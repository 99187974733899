import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";

export default class weChat {
    constructor() {
        this.http = new HttpHandler();
    }

    /**
     * 新增任务微信模板
     * @param data
     * @returns {Promise<AxiosResponse<any>>}
     */
    tempLateAdd(data) {
        let url = '/api/weChat/tempLateAdd';
        return this.http.post(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }
    tempLateUpd(data) {
        let url = '/api/weChat/tempLateUpd';
        return this.http.put(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    /**
     * 获取模板
     * @returns {*}
     */
    getTemplate(){
        let url = '/api/weChat/template';
        return this.http.get(url, null, HttpConstant.FORM_DATA_REQ);
    }

    /**
     *获取网页签名配置
     */
    getMpConfig(){
        let url = '/api/weChat/getMpConfig';
        return this.http.get(url, null, HttpConstant.FORM_DATA_REQ);
    }
    getMpSubscribe(data) {
        let url = '/api/weChat/getMpSubscribe';
        return this.http.post(url, data, HttpConstant.FORM_DATA_REQ);
    }
    addSubNumber(data){
        let url = '/api/weChat/addSubNumber';
        return this.http.put(url, data, HttpConstant.FORM_DATA_REQ);
    }
    synSubTemplate(data) {
        let url = '/api/weChat/synSubTemplate';
        return this.http.post(url, data, HttpConstant.FORM_DATA_REQ);
    }
}